<template>
  <div class="ori-enterprise">
    <ori-header />
    <co-card />
    <match-filter />

    <div
      class="enterprise-list-wrap"
      @click="$router.push('/enterprise-detail')"
    >
      <div class="enterprise-content w1200">
        <el-row :gutter="15">
          <el-col :span="12" v-for="i in list" :key="i.id">
            <div class="enterprise-box">
              <div class="logo">
                <img :src="i.logoBig" alt="" srcset="" />
              </div>
              <div class="info">
                <div class="name-share solo-row">
                  <span>{{ i.coName }}</span>
                  <span>
                    <i class="el-icon-share"></i>
                  </span>
                </div>
                <div class="info-row">
                  <span>企业名称：</span>
                  <span>{{ i.title }}</span>
                </div>
                <div class="info-row">
                  <span>所在地区：</span>
                  <span>{{ i.add }}</span>
                  <span class="rz-price">
                    融资金额：
                    <span class="price">
                      {{ i.lowPrice }} - {{ i.heightPrice }}万
                    </span>
                  </span>
                </div>
                <div class="info-row">
                  <span>融资需求：</span>
                  <span>{{ i.rzTag }}</span>
                </div>
                <div class="info-row">
                  <span>行业领域：</span>
                  <span class="hy-tag">{{ i.hyTag }}</span>
                  <span class="look-time">
                    <i class="el-icon-view"></i>
                    {{ i.lookTime }}
                  </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 分页 -->
    <div class="w1200 pagination-wrap">
      <ori-pagination />
    </div>
    <talk-card />
    <page-foot />
  </div>
</template>

<script>
import { coCardData } from "@/Mock";
import CoCard from "../../../components/ori-com/CoCard.vue";
import TalkCard from "../../../components/ori-com/TalkCard.vue";
import PageFoot from "../../../components/ori-com/PageFoot.vue";
import MatchFilter from "../../../components/ori-com/Select/MatchFilter.vue";
export default {
  components: { PageFoot, MatchFilter,CoCard,TalkCard},
  name: "ori-enterprise",
  data() {
    return {
      list: coCardData.list,
    };
  },
};
</script>

<style lang="scss" scoped>
.ori-enterprise {
  width: 100%;
  overflow: hidden;
  background-color: #f9f9f9;

  .enterprise-list-wrap {
    margin-top: 20px;

    .enterprise-box {
      cursor: pointer;
      margin-bottom: 15px;
      padding: 20px;
      background: #ffffff;

      display: flex;
      align-items: flex-start;

      &:hover,
      &:active {
        box-shadow: 0 0 15px #ddd;
        .logo img {
          transform: scale(1.2);
        }
      }

      .logo {
        width: 120px;
        height: 120px;
        box-sizing: border-box;
        overflow: hidden;
        margin-right: 18px;

        img {
          transition: all 0.8s ease-in;
        }
      }

      .info {
        flex: 1 1 auto;
        position: relative;
        overflow: hidden;

        .name-share {
          margin-top: -3px;
          margin-bottom: 5px;
          padding-right: 30px;
          span:nth-child(1) {
            font-size: 14px;
            font-weight: 700;
            color: #212c46;
          }

          span:nth-child(2) {
            position: absolute;
            right: 0;
            font-size: 20px;
            color: #999;
          }
        }

        .info-row {
          position: relative;
          margin-bottom: 7px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          span:nth-child(1) {
            color: #999;
          }

          .rz-price {
            position: absolute;
            right: 0;
            color: #999;
            .price {
              font-size: 18px;
              font-weight: 700;
              color: #e62d31;
            }
          }

          .hy-tag {
            display: inline-block;
            height: 26px;
            line-height: 26px;
            padding: 0 14px;
            background: #f4f5f9;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
          }

          .look-time {
            i {
              font-size: 14px;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
            }
            position: absolute;
            right: 0;
            line-height: 26px;
            color: #999;
          }
        }
      }
    }
  }

  .pagination-wrap {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 0;
    display: flex;
    justify-content: center;
  }
}
</style>