<template>
  <ori-card-wrap
    class="co-card"
    zhTitle="企业库"
    enTitle="EnterpriseLibrary"
    desc="全领域创新创业企业资源"
  >
    <span slot="more">查看更多></span>

    <template #default>
      <div class="left-content">
        <div
          class="box"
          v-for="(co, index) in coData"
          :key="co.id"
          v-show="index < 3"
        >
          <div class="logo">
            <img :src="co.logo" alt="" srcset="" />
          </div>
          <div class="box-right">
            <div class="rz-xm info-row">
              <el-tag class="rz-tag" size="mini" effect="dark">
                {{ co.rzTag }}
              </el-tag>

              <span class="xm-title">
                {{ co.coName }}
              </span>
            </div>

            <div class="co-add info-row">
              <el-row :gutter="15">
                <el-col :span="12">
                  <div>
                    <span class="label"> 企业名称： </span>
                    <span class="co-title">{{ co.title }}</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span class="label add">
                      <i class="el-icon-location-outline"></i>
                    </span>
                    <span>{{ co.add }}</span>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="hyly info-row">
              <span>行业领域：</span>
              <span>{{ co.hyTag }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <ul>
          <li v-for="co in coData4" :key="co.id">
            <div class="content-top">
              <div class="logo">
                <img :src="co.logo" alt="" srcset="" />
              </div>
              <div class="info">
                <div class="title" :title="co.coName">
                  {{ co.coName }}
                </div>
                <div class="add">
                  <i class="el-icon-location-outline"></i>

                  <span>
                    {{ co.add }}
                  </span>
                </div>
                <div class="name">
                  <span>企业名称：</span>
                  <span>{{ co.title }}</span>
                </div>
              </div>
            </div>
            <div class="content-bottom">
              <span>行业领域：</span>
              <span> {{ co.hyTag }} </span>
            </div>
            <span class="rz-tag">
              <el-tag class="rz-tag" size="mini" effect="dark">
                {{ co.rzTag }}
              </el-tag>
            </span>
          </li>
        </ul>
      </div>
    </template>
  </ori-card-wrap>
</template>

<script>
import { coCardData } from "@/Mock";
export default {
  name: "co-card",
  data() {
    return {
      coData: coCardData.list,
    };
  },
  computed: {
    coData4() {
      return this.coData.slice(3, 7);
    },
  },
};
</script>

<style lang="scss" scoped>
.co-card {
  .left-content {
    display: inline-block;
    width: 590px;
    height: 353px;
    background-image: url("../../assets/images/ori/enterprise-bg.png");
    background-size: 590px 353px;

    margin-right: 10px;
    .box {
      cursor: pointer;
      height: 117px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 10px;
      display: flex;
      align-items: flex-start;

      &:hover,
      &:active {
        .logo img {
          transform: scale(1.2);
        }
      }

      .logo {
        margin-top: 2px;
        margin-right: 23px;
        overflow: hidden;

        img {
          transition: all 0.3s ease-in;
        }
      }

      .rz-xm {
        .rz-tag {
          margin-right: 8px;
        }
        .xm-title {
          font-size: 14px;
          font-weight: 700;
          color: #212c46;
          line-height: 19px;
          vertical-align: bottom;
        }
      }

      .co-add {
        margin-top: 10px;
        color: #999999;
        line-height: 20px;
        font-size: 12px;
        font-weight: 400;
        .co-title {
          overflow: hidden;
          width: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .label.add {
          font-size: 20px;
          padding-right: 6px;
          vertical-align: middle;
        }
      }

      .hyly {
        margin-top: 5px;
        span:nth-child(1) {
          font-size: 12px;
          line-height: 26px;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }
        span:nth-child(2) {
          display: inline-block;
          padding: 0 13px;
          height: 26px;
          font-size: 12px;
          line-height: 26px;
          background: #f4f5f9;
          border-radius: 3px;
          margin-right: 5px;
        }
      }

      .info-row {
        overflow: hidden;
        width: 370px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .box + .box {
      border-top: 1px dashed #e0e0ec;
    }
  }

  .right-content {
    display: inline-block;
    width: 600px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        display: inline-block;
        width: 290px;
        height: 170px;
        background: #ffffff;
        padding: 17px 0;
        margin-left: 10px;
        transition: all 0.3s ease-in;
      }

      li:nth-child(3),
      li:nth-child(4) {
        margin-top: 13px;
      }

      li {
        cursor: pointer;
        .content-top {
          display: flex;
          align-items: flex-start;
          padding: 0 15px;
          padding-bottom: 20px;
          border-bottom: 1px dashed #e0e0ec;

          .logo {
            overflow: hidden;
            margin-right: 7px;
            img {
              transition: all 0.3s ease-in;
            }
          }

          .info {
            flex: 1 1 auto;
            max-width: 160px;
            .title {
              font-weight: bold;
              font-size: 14px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 160px;
            }
            .add {
              margin-top: 5px;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              vertical-align: middle;
              i {
                font-size: 18px;
                vertical-align: middle;
              }
            }
            .name {
              font-size: 12px;
              font-weight: 400;
              margin-top: 5px;
              span:nth-child(1) {
                color: #999;
              }
              span:nth-child(2) {
                color: #666;
              }
            }
          }
        }

        &:hover,
        &:active {
          .logo {
            img {
              transform: scale(1.2);
            }
          }
        }

        .content-bottom {
          padding: 0 10px;
          padding-top: 10px;
          span:nth-child(1) {
            line-height: 26px;
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #999999;
          }

          span:nth-child(2) {
            padding: 0 13px;
            height: 26px;
            line-height: 26px;
            background: #f4f5f9;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
          }
        }

        .rz-tag {
          position: absolute;
          top: 5px;
          z-index: 2;
        }
      }
    }
  }
}
</style>