<template>
  <ori-card-wrap
    class="talk-card"
    zhTitle="企业访谈"
    enTitle="Enterprise interview"
    desc="探访行业故事，传播知识价值"
  >
    <span slot="more">查看更多></span>
    <template>
      <div class="talk-content">
        <div class="talk-top">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="talk-wrap">
                <div class="img">
                  <img :src="img1" alt="" srcset="" />
                </div>
                <div>
                  <div class="talk-item">
                    <div class="title solo-row">
                      {{ list[0] ? list[0].title : "" }}
                    </div>
                    <div class="desc">
                      {{ list[0] ? list[0].desc : "" }}
                    </div>
                  </div>
                  <div class="talk-item">
                    <div class="title solo-row">
                      {{ list[1] ? list[1].title : "" }}
                    </div>
                    <div class="desc">
                      {{ list[1] ? list[1].desc : "" }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="talk-wrap">
                <div class="img">
                  <img :src="img1" alt="" srcset="" />
                </div>
                <div>
                  <div class="talk-item">
                    <div class="title solo-row">
                      {{ list[2] ? list[2].title : "" }}
                    </div>
                    <div class="desc">
                      {{ list[2] ? list[2].desc : "" }}
                    </div>
                  </div>
                  <div class="talk-item">
                    <div class="title solo-row">
                      {{ list[3] ? list[3].title : "" }}
                    </div>
                    <div class="desc">
                      {{ list[3] ? list[3].desc : "" }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="talk-bottom">
          <el-row class="talk-row" :gutter="20">
            <el-col :span="12">
              <div class="item">
                <div class="title">
                  {{ list[4] ? list[4].title : "" }}
                </div>
                <div class="desc-time">
                  <div class="desc">
                    {{ list[4] ? list[4].desc : "" }}
                  </div>
                  <div class="time">
                    {{ list[4] ? list[4].yearMonth : "" }}
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <div class="title">
                  {{ list[4] ? list[4].title : "" }}
                </div>
                <div class="desc-time">
                  <div class="desc">
                    {{ list[4] ? list[4].desc : "" }}
                  </div>
                  <div class="time">
                    {{ list[4] ? list[4].yearMonth : "" }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="talk-row" :gutter="20">
            <el-col :span="12">
              <div class="item">
                <div class="title">
                  {{ list[4] ? list[4].title : "" }}
                </div>
                <div class="desc-time">
                  <div class="desc">
                    {{ list[4] ? list[4].desc : "" }}
                  </div>
                  <div class="time">
                    {{ list[4] ? list[4].yearMonth : "" }}
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <div class="title">
                  {{ list[4] ? list[4].title : "" }}
                </div>
                <div class="desc-time">
                  <div class="desc">
                    {{ list[4] ? list[4].desc : "" }}
                  </div>
                  <div class="time">
                    {{ list[4] ? list[4].yearMonth : "" }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </ori-card-wrap>
</template>

<script>
import { talkData } from "@/Mock";
export default {
  name: "talk-card",
  data() {
    return {
      list: talkData.list,
      img1: talkData.img1,
      img2: talkData.img2,
    };
  },
};
</script>

<style lang="scss" scoped>
.talk-card {
  padding-bottom: 40px;
  .talk-content {
    width: 100%;

    .talk-wrap {
      cursor: pointer;
      display: flex;
      background-color: #fff;

      &:hover,
      &:active {
        img {
          transform: scale(1.2);
        }
      }

      img {
        transition: all 0.5s ease-in;
      }

      .img {
        width: 213px;
        height: 214px;
        overflow: hidden;
        position: relative;
      }

      .talk-item {
        cursor: pointer;
        padding: 15px;
        padding-top: 20px;
        width: 377px;
        height: 107px;
        opacity: 1;

        .title {
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
        }

        .desc {
          width: 344px;
          height: 40px;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-top: 10px;
        }
      }
    }

    .talk-bottom {
      margin-top: 10px;
      background: #fff;
      height: 174px;

      .talk-row {
        padding: 20px;
      }

      .talk-row + .talk-row {
        border-top: 1px solid #f4f5f9;
      }

      .item {
        .title {
          font-size: 14px;
          font-weight: 700;
          color: #333333;
          line-height: 18px;
          width: 550px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .desc-time {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          .desc {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            float: left;
          }
          .time {
            flex: 0 0 auto;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            float: right;
            padding: 0 15px;
          }
        }
      }
    }
  }
}
</style>